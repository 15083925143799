import { memo, useState } from "react"
import MobileController from "./MobileController";
import Mobilecontactform from "./Mobilecontactform";

function Mobcontroller(){
    const [formcontrol, setFormcontrol]=useState("none")
    
    return (<>
    <MobileController formcontrol={formcontrol} setFormcontrol={setFormcontrol}/>
    <Mobilecontactform formcontrol={formcontrol} setFormcontrol={setFormcontrol}/>
    </>)
}
export default memo(Mobcontroller);